<template>
  <div>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">Search</label>
                    <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                </div>
            </b-form-group>
        </div>
        <!-- table -->
        <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
          enabled: true,
          externalQuery: searchTerm
        }" :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }" :pagination-options="{
          enabled: true,
          perPage: pageLength
        }">
            <template slot="table-row" slot-scope="props">

                <!-- Column: Name -->
                <span v-if="props.column.field === 'fullName'" class="text-nowrap">
                    <b-avatar :src="props.row.logo" class="mx-1" />
                    <span class="text-nowrap">{{ props.row.name }}</span>
                </span>

                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'status'">
                    <b-badge :variant="statusVariant(props.row.status)">
                        {{ props.row.status }}
                    </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                    <span>
                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                            <template v-slot:button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                            </template>
                            <b-dropdown-item @click="showEditModal(props.row)">
                                <feather-icon icon="Edit2Icon" class="mr-50" />
                                <span>Editar</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="showDeleteModal(props.row)">
                                <feather-icon icon="TrashIcon" class="mr-50" />
                                <span>Eliminar</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </span>
                </span>

                <!-- Column: Common -->
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                            Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                            last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-modal.modal-products variant="gradient-success"
            class="btn-icon rounded-circle">
            <feather-icon icon="PlusIcon" />
        </b-button>

        <!--Modal main-->
        <b-modal id="modal-products" class="position-relative" ref="modal-products" cancel-variant="outline-secondary"
            ok-title="Guardar" cancel-title="Cerrar" centered :title="mainModalTitle" @ok="handleOk" @hidden="hideModal"
            :busy="overlay" size="lg">
            <b-overlay :show="overlay" rounded="sm">
                <validation-observer ref="validationRules">
                    <b-form @submit.stop.prevent="validateForm">
                        <b-row>
                            <b-col md="6">
                                <div class="card shadow-none bg-transparent border-primary">
                                    <div class="card-body">
                                        <div class="card-title">Productos</div>
                                        <b-col cols="12">
                                            <b-form-group label-for="slctProvider" label="Proveedor">
                                                <b-form-select id="slctProvider" v-model="idProvider" :options="providers" />
                                            </b-form-group>

                                            <b-form-group label-for="txtBrand" label="Marca">
                                                <validation-provider #default="{ errors }" ref="txtBrandProvider" name="Marca" rules="required">
                                                    <b-input-group class="input-group-merge" :class="isBrandValid.value">
                                                        <b-input-group-prepend is-text>
                                                            <feather-icon icon="ShoppingBagIcon" />
                                                        </b-input-group-prepend>
                                                        <b-form-input id="txtBrand" placeholder="Marca" v-model="brand" :state="errors.length > 0 ? false:null" 
                                                            @input="validateField(brand, isBrandValid)" @blur="validateField(brand, isBrandValid)"/>
                                                    </b-input-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>

                                            <b-form-group label-for="txtHealth" label="Registro Sanitario">
                                                <validation-provider #default="{ errors }" ref="txtHealthProvider" name="Registro" rules="required">
                                                    <b-input-group class="input-group-merge" :class="isHealthValid.value">
                                                        <b-input-group-prepend is-text>
                                                            <feather-icon icon="ArchiveIcon" />
                                                        </b-input-group-prepend>
                                                        <b-form-input id="txtHealth" placeholder="Registro" v-model="health_record" :state="errors.length > 0 ? false:null" 
                                                            @input="validateField(health_record, isHealthValid)" @blur="validateField(health_record, isHealthValid)"/>
                                                    </b-input-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>

                                            <b-row>
                                                <b-col cols="8">
                                                    <b-form-group label-for="txtWeight" label="Peso Neto">
                                                        <validation-provider #default="{ errors }" ref="txtWeightProvider" rules="required" name="Peso">
                                                            <b-input-group class="input-group-merge" :class="isWeightValid.value">
                                                                <b-input-group-prepend is-text>
                                                                    <feather-icon icon="EditIcon" />
                                                                </b-input-group-prepend>
                                                                <b-form-input id="txtWeight" placeholder="Peso" v-model="weight"
                                                                    :state="errors.length > 0 ? false:null" type="number"
                                                                    @input="validateField(weight, isWeightValid)" @blur="validateField(weight, isWeightValid)"/>
                                                            </b-input-group>
                                                            <small class="text-danger">{{ errors[0] }}</small>
                                                        </validation-provider>
                                                    </b-form-group>
                                                </b-col>
                                                <b-col cols="4">
                                                    <b-form-group label-for="slctUnidad" label="Unidad">
                                                            <b-form-select id="slctUnidad" v-model="wProduct" :options="weights" />
                                                    </b-form-group>
                                                </b-col>
                                            </b-row>

                                            <b-form-group label-for="observation" label="Observacion">
                                                <validation-provider #default="{ errors }" ref="txtObservationProvider" name="Observacion" rules="required">
                                                    <b-input-group class="input-group-merge" :class="isObservationValid.value">
                                                        <b-input-group-prepend is-text>
                                                            <feather-icon icon="EditIcon" />
                                                        </b-input-group-prepend>
                                                        <b-form-textarea id="observation" rows="2" placeholder="Observacion" v-model="observation"
                                                            :state="errors.length > 0 ? false:null" 
                                                            @input="validateField(observation, isObservationValid)" @blur="validateField(observation, isObservationValid)"/>
                                                    </b-input-group>
                                                    <small class="text-danger">{{ errors[0] }}</small>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        
                                    </div>
                                </div>
                            </b-col>

                            <b-col md="6">
                                <div class="card shadow-none bg-transparent border-primary">
                                    <div class="card-body">
                                        <div class="card-title">Caja</div>

                                        <b-row>
                                            <b-col cols="8">
                                                <b-form-group label-for="txtWidth" label="Ancho">
                                                    <validation-provider #default="{ errors }" ref="txtWidthProvider" rules="required" name="Ancho">
                                                        <b-input-group class="input-group-merge" :class="isWidthValid.value">
                                                            <b-input-group-prepend is-text>
                                                                <feather-icon icon="EditIcon" />
                                                            </b-input-group-prepend>
                                                            <b-form-input id="txtWidth" placeholder="Ancho" v-model="width"
                                                                :state="errors.length > 0 ? false:null" type="number"
                                                                @input="validateField(width, isWidthValid)" @blur="validateField(width, isWidthValid)"/>
                                                        </b-input-group>
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="4">
                                                <b-form-group label-for="slctUnidad" label="Unidad">
                                                    <b-form-select id="slctUnidad" v-model="dWidth" :options="distance" />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col cols="8">
                                                <b-form-group label-for="txtLong" label="Largo">
                                                    <validation-provider #default="{ errors }" ref="txtLongProvider" rules="required" name="Largo">
                                                        <b-input-group class="input-group-merge" :class="isLongValid.value">
                                                            <b-input-group-prepend is-text>
                                                                <feather-icon icon="EditIcon" />
                                                            </b-input-group-prepend>
                                                            <b-form-input id="txtLong" placeholder="Largo" v-model="long"
                                                                :state="errors.length > 0 ? false:null" type="number"
                                                                @input="validateField(long, isLongValid)" @blur="validateField(long, isLongValid)"/>
                                                        </b-input-group>
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="4">
                                                <b-form-group label-for="slctUnidad" label="Unidad">
                                                    <b-form-select id="slctUnidad" v-model="dLong" :options="distance" />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col cols="8">
                                                <b-form-group label-for="txtHeight" label="Alto">
                                                    <validation-provider #default="{ errors }" ref="txtHeightProvider" rules="required" name="Alto">
                                                        <b-input-group class="input-group-merge" :class="isHeightValid.value">
                                                            <b-input-group-prepend is-text>
                                                                <feather-icon icon="EditIcon" />
                                                            </b-input-group-prepend>
                                                            <b-form-input id="txtHeight" placeholder="Alto" v-model="high"
                                                                :state="errors.length > 0 ? false:null" type="number"
                                                                @input="validateField(high, isHeightValid)" @blur="validateField(high, isHeightValid)"/>
                                                        </b-input-group>
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="4">
                                                <b-form-group label-for="slctUnidad" label="Unidad">
                                                    <b-form-select id="slctUnidad" v-model="dHigh" :options="distance" />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col cols="8">
                                                <b-form-group label-for="txtWeightBox" label="Peso">
                                                    <validation-provider #default="{ errors }" ref="txtWeightBoxProvider" rules="required" name="Peso">
                                                        <b-input-group class="input-group-merge" :class="isWeightBoxValid.value">
                                                            <b-input-group-prepend is-text>
                                                                <feather-icon icon="EditIcon" />
                                                            </b-input-group-prepend>
                                                            <b-form-input id="txtWeightBox" placeholder="Peso" v-model="weight_by_box"
                                                                :state="errors.length > 0 ? false:null" type="number"
                                                                @input="validateField(weight_by_box, isWeightBoxValid)" @blur="validateField(weight_by_box, isWeightBoxValid)"/>
                                                        </b-input-group>
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="4">
                                                <b-form-group label-for="slctUnidad" label="Unidad">
                                                    <b-form-select id="slctUnidad" v-model="wByBox" :options="weights" />
                                                </b-form-group>
                                            </b-col>
                                        </b-row>

                                        <b-row>
                                            <b-col cols="8">    
                                                <b-form-group label-for="txtQuantity" label="Cantidad">
                                                    <validation-provider #default="{ errors }" ref="txtQuantityProvider" name="Cantidad" rules="required">
                                                        <b-input-group class="input-group-merge" :class="isQuantityValid.value">
                                                            <b-input-group-prepend is-text>
                                                                <feather-icon icon="EditIcon" />
                                                            </b-input-group-prepend>
                                                            <b-form-input id="txtQuantity" type="number" placeholder="Cantidad" v-model="quanty_by_box" :state="errors.length > 0 ? false:null" 
                                                                @input="validateField(quanty_by_box, isQuantityValid)" @blur="validateField(quanty_by_box, isQuantityValid)"/>
                                                        </b-input-group>
                                                        <small class="text-danger">{{ errors[0] }}</small>
                                                    </validation-provider>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </b-overlay>
        </b-modal>

        <b-modal id="modal-danger" ref="modal-delete" ok-variant="danger" ok-title="Si" cancel-title="No"
            modal-class="modal-danger" centered title="Eliminar" @ok="deleteProduct" :busy="deleteOverlay">
            <b-overlay :show="deleteOverlay" rounded="sm">
                <b-card-text>
                    Esta seguro que desea eliminar el producto {{this.brand}}?
                </b-card-text>
            </b-overlay>
        </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BOverlay,
    BModal, VBModal, BForm, BRow, BCol, BFormTextarea, BCardText, BFormFile, BImg, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import CardBasicColumns from '../card/card-basic/CardBasicColumns.vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import { required } from '@validations'
import en from "vee-validate/dist/locale/en.json"
import es from "vee-validate/dist/locale/es.json"
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

localize({
    en,
    es
});
localize("es");

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        CardBasicColumns,
        BButton,
        BModal,
        BForm,
        BFormInput,
        BFormGroup,
        BRow,
        BCol,
        BFormTextarea,
        BCardText,
        BFormFile,
        BImg,
        BInputGroup,
        BInputGroupPrepend,
        ValidationProvider,
        ValidationObserver,
        BFormInvalidFeedback,
        BOverlay,
    },
    directives: {
        Ripple,
        'b-modal': VBModal,
    },
    data() {
        return {
            isBrandValid: {
                value: null
            },
            isHealthValid: {
                value: null
            },
            isWeightValid: {
                value:null
            },
            isObservationValid: {
                value: null
            },
            isWidthValid:{
                value: null
            },
            isLongValid:{
                value:null
            },
            isHeightValid: {
                value: null
            },
            isWeightBoxValid: {
                value: null
            },
            isQuantityValid: {
                value: null
            },
            rows: [],
            searchTerm: '',
            pageLength: 5,
            dir: false,
            overlay: false,
            deleteOverlay: false,
            mainModalTitle: "Nuevo Producto",
            columns: [
                {
                    label: 'Proveedor',
                    field: 'providerData.name',
                },
                {
                    label: 'Marca',
                    field: 'brand',
                },
                {
                    label: 'Peso(Kg)',
                    field: 'weight'
                },
                
                {
                    label: 'Observación',
                    field: 'observation'
                },
                {
                    label: 'Acciones',
                    field: 'action',
                },
            ],
            idProduct: 0,
            idProvider: 0,
            providerData: "",
            health_record: "",
            quanty_by_box: "",
            weight_by_box: "",
            brand: "",
            weight: "",
            width: "",
            long: "",
            high: "",
            observation: "",
            logo: "",
            config: "",
            providers: [],
            dWidth: 1,
            dLong: 1,
            dHigh: 1,
            wByBox: 1,
            wProduct: 1,
            distance: [
                { text: 'cm', value: 0 },
                { text: 'mt', value: 1 },
                { text: 'pulg', value: 2 },
            ],
            weights: [
                { text: "Lb", value: 0 },
                { text: "Kg", value: 1 },
                { text: "g", value: 2 }
            ],
        }
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },
    created() {
        this.getProviders()
        this.getProducts()
    },
    methods:{
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.validateForm()
        },
        showDeleteModal(item) {
            this.idProduct = item.id
            this.brand = item.brand
            this.$refs['modal-delete'].show()
        },
        async getProducts(){
            const path = '/product/'
            await axios.get(path).then((response) => {
                this.rows = response.data
            }).catch((error) => {
                console.log(error)
            })
        },
        async getProviders() {
            const path = "/provider/"
            await axios.get(path).then(res => {
                for (let provider of res.data){
                    this.providers.push({value: provider.id, text:provider.name})
                }
                this.idProvider = this.providers[0].value
            })
        },
        async validateForm() {
            const txtBrandValid = await this.$refs.txtBrandProvider.validate().then(value => { return value.valid })
            const txtHealthValid = await this.$refs.txtHealthProvider.validate().then(value => { return value.valid })
            const txtWeightValid = await this.$refs.txtWeightProvider.validate().then(value => { return value.valid })
            const txtObservationValid = await this.$refs.txtObservationProvider.validate().then(value => { return value.valid })
            const txtWidthValid = await this.$refs.txtWidthProvider.validate().then(value => { return value.valid })
            const txtLongValid = await this.$refs.txtLongProvider.validate().then(value => { return value.valid })
            const txtHeightValid = await this.$refs.txtHeightProvider.validate().then(value => { return value.valid })
            const txtWeightBoxValid = await this.$refs.txtWeightBoxProvider.validate().then(value => { return value.valid })
            const txtQuantityValid = await this.$refs.txtQuantityProvider.validate().then(value => { return value.valid })

            this.validateField(this.brand, this.isBrandValid)
            this.validateField(this.health_record, this.isHealthValid)
            this.validateField(this.weight, this.isWeightValid)
            this.validateField(this.observation, this.isObservationValid)
            this.validateField(this.width, this.isWidthValid)
            this.validateField(this.long, this.isLongValid)
            this.validateField(this.high, this.isHeightValid)
            this.validateField(this.weight_by_box, this.isWeightBoxValid)
            this.validateField(this.quanty_by_box, this.isQuantityValid)

            if (txtBrandValid && txtHealthValid && txtWeightValid && txtObservationValid &&
                txtWidthValid && txtLongValid && txtHeightValid && txtWeightBoxValid && txtQuantityValid){
                    if(this.idProduct>0){
                        this.updateProduct()
                    }else{
                        this.createProduct()
                    }
            }
        },
        validateField(value, validationField) {
            return value.length == 0 ? validationField.value = 'is-invalid' : validationField.value = null
        },
        async createProduct(){
            const path = `/product/`
            await axios.post(path, {
                provider: this.idProvider,
                health_record: this.health_record,
                quanty_by_box: this.quanty_by_box,
                weight_by_box: this.weight_by_box,
                brand: this.brand,
                weight: this.weight,
                width: this.width,
                long: this.long,
                high: this.high,
                observation: this.observation
            }).then((response) => {
                console.log(response.data)
                this.getProducts()
                this.hideModal()
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: this.mainModalTitle,
                        icon: 'InfoIcon',
                        variant: 'success',
                        text: `El producto ${this.brand} ha sido creado con exito!!!`,
                    },
                })
                
            }).catch((error) => {
                console.log(error)
            })
        },
        showEditModal(product) {
            this.mainModalTitle = `Producto ${product.brand}`
            this.idProduct = product.id;
            this.idProvider = product.provider;
            this.health_record = product.health_record;
            this.quanty_by_box = product.quanty_by_box;
            // this.weight_by_box= Math.round(id["weight_by_box"] * 2.2 * 100)/100;
            this.weight_by_box = product.weight_by_box;
            this.brand = product.brand;
            // this.weight= Math.round(id["weight"] * 2.2 * 100)/100;
            this.weight = product.weight
            this.width = product.width
            this.long = product.long
            this.high = product.high
            this.provider = product.providerData.name
            this.observation = product.observation
            this.logo = product.providerData.logo
            this.dWidth = 1;
            this.dLong = 1;
            this.dHigh = 1;
            this.wByBox = 1;
            this.wProduct = 1;
            this.showModal()
        },
        async updateProduct(){
            const path = `/product/${this.idProduct}/`
            if (this.dWidth == 0) { this.width = this.width / 100 }
            else if (this.dWidth == 2) { this.width = Math.round(this.width * 1000 / 39.37) / 1000 }
            if (this.dLong == 0) { this.long = this.long / 100 }
            else if (this.dLong == 2) { this.long = Math.round(this.long * 1000 / 39.37) / 1000 }
            if (this.dHigh == 0) { this.high = this.high / 100 }
            else if (this.dHigh == 2) { this.high = Math.round(this.high * 1000 / 39.37) / 1000 }
            if (this.wProduct == 0) { this.weight = Math.round(this.weight * 1000 / 2.2046) / 1000 }
            else if (this.wProduct == 2) { this.weight = this.weight / 1000 }
            if (this.wByBox == 0) { this.weight_by_box = Math.round(this.weight_by_box * 1000 / 2.2046) / 1000 }
            else if (this.wByBox == 2) { this.weight_by_box = this.weight_by_box / 1000 }

            await axios.patch(path, {
                provider: this.idProvider,
                health_record: this.health_record,
                quanty_by_box: this.quanty_by_box,
                weight_by_box: this.weight_by_box,
                brand: this.brand,
                weight: this.weight,
                width: this.width,
                long: this.long,
                high: this.high,
                observation: this.observation,
            }).then((response) => {
                this.getProducts()
                this.hideModal()
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Producto editado',
                        icon: 'InfoIcon',
                        variant: 'success',
                        text: `El producto ${this.brand} se ha actualizado con exito!!!`,
                    },
                })
            })
            .catch((error) => {
                console.log(error)
            })
        },
        async deleteProduct(){
            const path = `/product/${this.idProduct}/`
            await axios.delete(path).then((response) => {
                this.getProducts()
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Eliminado',
                        icon: 'Trash2Icon',
                        variant: 'danger',
                        text: 'El producto ha sido eliminado con exito!!!',
                    },
                })
            }).catch((error) => {
                console.log(error)
            })
        },
        hideModal() {
            this.$refs['modal-products'].hide()
            this.resetModal()
        },
        showModal() {
            this.$refs['modal-products'].show()
        },
        resetModal() {
            this.mainModalTitle = "Nuevo Producto"
            this.idProduct = 0
            //this.idProvider = 0
            this.health_record = ""
            this.quanty_by_box = ""
            this.weight_by_box = ""
            this.brand = ""
            this.weight = ""
            this.width = ""
            this.long = ""
            this.high = ""
            this.provider = ""
            this.observation = ""
            this.logo = ""
            this.dWidth = 1
            this.dLong = 1
            this.dHigh = 1
            this.wByBox = 1
            this.wProduct = 1

            this.isBrandValid.value = null
            this.isHealthValid.value = null
            this.isWeightValid.value = null
            this.isObservationValid.value = null
            this.isWidthValid.value = null
            this.isLongValid.value = null
            this.isHeightValid.value = null
            this.isWeightBoxValid.value = null
            this.isQuantityValid.value = null

        }
    }
}
</script>

<style lang="scss">
    .add-button {
        float: right;
    }
    @import '@core/scss/vue/libs/vue-good-table.scss';
</style>